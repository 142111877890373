<template>
  <div class="summary">
    <v-summary-header :rmaId="getRmaApplicationId" />

    <div class="row align-self-stretch">
      <div
        class="d-flex col-lg-4 p-0 pt-3 mb-3"
        :class="{
          'pr-lg-3': !isRightLangDirection,
          'pl-lg-3': isRightLangDirection,
        }"
      >
        <v-invoice-data :isRight="isRightLangDirection" :data="getInvoice" />
      </div>

      <div class="d-flex col-lg-4 p-0 pt-3 mb-3 pr-lg-3 pl-lg-3">
        <v-complaint-type
          :isRight="isRightLangDirection"
          :complaintType="getComplaintType"
        />
      </div>

      <div
        class="d-flex col-lg-4 p-0 pt-3 mb-3"
        :class="{
          'pl-lg-3': !isRightLangDirection,
          'pr-lg-3': isRightLangDirection,
        }"
      >
        <v-complaint-method
          :isRight="isRightLangDirection"
          :complaintMethod="getComplaintMethod"
        />
      </div>
    </div>

    <div class="row">
      <div class="d-flex col-lg-12 p-0 pl-lg-0 pr-lg-0 pt-3">
        <v-products :isRight="isRightLangDirection" :lines="getLines" />
      </div>
    </div>

    <div class="row align-self-stretch">
      <div class="card col-12 mb-12 mb-3 mt-3">
        <h5 class="card-title d-flex">
          <span class="d-flex">{{
            $t("summary.shipping-details.form.title")
          }}</span>
        </h5>
        <div class="row">
          <div class="d-flex col-lg-4 mb-3 mt-3 pl-lg-3 pr-lg-4">
            <v-pickup-address
              :isRight="isRightLangDirection"
              :data="getPickupAddress"
            />
          </div>

          <div class="d-flex col-lg-4 mb-3 mt-3 pl-lg-4 pr-lg-4">
            <v-return-address
              :isRight="isRightLangDirection"
              :data="getReturnAddress"
            />
          </div>

          <div class="d-flex col-lg-4 mb-3 mt-3 pl-lg-4 pr-lg-3">
            <v-contact-data
              :isRight="isRightLangDirection"
              :data="getContactData"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row align-self-stretch">
      <div class="card col-12 mb-12 mb-3 mt-3">
        <h5 class="card-title d-flex">
          <span class="d-flex">{{
            $t("summary.billing-details.form.title")
          }}</span>
        </h5>
        <div class="row">
          <div class="d-flex col-lg-8 mb-3 mt-3 pl-lg-4 pr-lg-4">
            <v-bank-details
              :isRight="isRightLangDirection"
              :data="getBankDetails"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row summary-footer">
      <div class="card col-12 mt-3 p-2 mb-2">
        <div class="card-body">
          <button class="btn btn-link no-bg p-0 m-0" @click="handleReset">
            {{ $t("label.rma.sendNext") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import VSummaryHeader from "./VSummaryHeader";
import VInvoiceData from "./VInvoiceData";
import VContactData from "./VContactData";
import VProducts from "./VProducts";
import VComplaintType from "./VComplaintType";
import VComplaintMethod from "./VComplaintMethod";
import VPickupAddress from "./VPickupAddress";
import VReturnAddress from "./VReturnAddress";
import VBankDetails from "./VBankDetails";

export default {
  name: "Summary",

  components: {
    VSummaryHeader,
    VInvoiceData,
    VProducts,
    VReturnAddress,
    VPickupAddress,
    VContactData,
    VComplaintType,
    VComplaintMethod,
    VBankDetails,
  },

  methods: {
    ...mapActions("app", ["resetStoreData"]),

    handleReset() {
      this.$router.push("/");
      this.resetStoreData();
    },
  },

  computed: {
    ...mapGetters("app", ["isRightLangDirection"]),
    ...mapGetters("rmaForm", [
      "getRmaApplicationId",
      "getInvoice",
      "getComplaintType",
      "getComplaintMethod",
      "getLines",
      "getContactData",
      "getPickupAddress",
      "getReturnAddress",
      "getBankDetails",
    ]),
  },
};
</script>

<style scoped>
.summary {
  margin: 0 15px;
}
</style>
