<template>
  <div class="card pt-3 pb-3 mb-3 col-12">
    <h5
      class="card-title text-center"
      :class="{
        'text-lg-left': !isRight,
        'text-lg-right': isRight,
      }"
    >
      {{ $t("summary.products.header") }}
    </h5>

    <div class="card-body">
      <div v-for="(line, index) in lines" :key="index">
        <div class="alert alert-info m-0 pl-2 pr-2 pt-2 pb-2">
          <strong class="text-dark"
            >{{ index + 1 }}. {{ line.itemName }} -
            {{ line.serialNumber.join(", ") }}</strong
          >
        </div>

        <v-reason :line="line" />

        <!-- <v-sales-reason
          v-if="line.returnReason === 'SALES_REASON'"
          :line="line"
        />

        <v-service-reason
          v-if="line.returnReason === 'SERVICE_REASON'"
          :line="line"
        />

        <v-transport-reason
          v-if="line.returnReason === 'TRANSPORT_REASON'"
          :line="line"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
// import VSalesReason from "./VSalesReason";
// import VServiceReason from "./VServiceReason";
// import VTransportReason from "./VTransportReason";
import VReason from "./VReason";

export default {
  name: "VProducts",

  components: {
    // VSalesReason,
    // VServiceReason,
    // VTransportReason,
    VReason,
  },

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    lines: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    console.log(this.lines);
  },
};
</script>
