<template>
  <div class="card pt-3 pb-4 col-12">
    <h6
      class="card-title text-center"
      :class="{
        'text-lg-left': !isRight,
        'text-lg-right': isRight,
      }"
    >
      {{ $t("summary.complaint-method.header") }}
    </h6>

    <div class="card-body">
      <div class="form-group">
        <strong v-show="complaintMethod === 'CASH_BACK'">
          {{ $t("label.complaint-method.cashback") }}
        </strong>
        <strong v-show="complaintMethod === 'EXCHANGE'">
          {{ $t("label.complaint-method.replacement") }}
        </strong>
        <strong v-show="complaintMethod === 'REPAIR'">
          {{ $t("label.complaint-method.service") }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VComplaintMethod",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    complaintMethod: {
      type: String,
      default: null,
    },
  },
};
</script>
