<template>
  <div class="mt-2 mb-4 pt-2 pb-2 pl-2">
    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-xs-4 col-md-2">
        <label>{{ $t("summary.description") }}:</label>
      </div>

      <div class="col-12 col-xs-8 col-md-10">
        <strong>{{ line.returnDescription }}</strong>
      </div>
    </div>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-xs-4 col-md-2">
        <label>{{ $t("summary.state") }}:</label>
      </div>

      <div class="col-12 col-xs-8 col-md-10">
        <strong>{{ $t(stateLocale) }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
const productState = {
  NEW: "label.reason.sales.state.new",
  NO_ORIGINAL_PACKAGING: "label.reason.sales.state.no.orginal.packing",
  OPEN: "label.reason.sales.state.open",
  OPEN_NOT_USED: "label.reason.sales.state.open.not.used",
  OPEN_USED: "label.reason.sales.state.open.used",
};

export default {
  name: "VReason",

  data() {
    return {
      stateLocale: null,
    };
  },

  props: {
    line: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    localGetProductState() {
      this.stateLocale = productState[this.line.itemState];
    },
  },

  mounted() {
    this.localGetProductState();
  },
};
</script>
