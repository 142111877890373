var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card pt-3 pb-4 col-12"},[_c('h6',{staticClass:"card-title text-center",class:{
      'text-lg-left': !_vm.isRight,
      'text-lg-right': _vm.isRight,
    }},[_vm._v(" "+_vm._s(_vm.$t("summary.invoice.header"))+" ")]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"form-inline"},[_c('div',{staticClass:"form-inline w-100"},[_c('label',{staticClass:"w-50",class:{
            'justify-content-start': !_vm.isRight,
            'justify-content-end': _vm.isRight,
          }},[_vm._v(_vm._s(_vm.$t("summary.invoice.number"))+":")]),_c('strong',{staticClass:"w-50",class:{
            'justify-content-start': !_vm.isRight,
            'justify-content-end': _vm.isRight,
          }},[_vm._v(_vm._s(_vm.data.invoiceNumber || "-"))])]),_c('div',{staticClass:"form-inline w-100"},[_c('label',{staticClass:"w-50",class:{
            'justify-content-start': !_vm.isRight,
            'justify-content-end': _vm.isRight,
          }},[_vm._v(_vm._s(_vm.$t("summary.client.number"))+":")]),_c('strong',{class:{
            'justify-content-start': !_vm.isRight,
            'justify-content-end': _vm.isRight,
          }},[_vm._v(_vm._s(_vm.data.clientNumber || "-"))])]),_c('div',{staticClass:"form-inline w-100"},[_c('label',{staticClass:"w-50",class:{
            'justify-content-start': !_vm.isRight,
            'justify-content-end': _vm.isRight,
          }},[_vm._v(_vm._s(_vm.$t("summary.order.number"))+":")]),_c('strong',{class:{
            'justify-content-start': !_vm.isRight,
            'justify-content-end': _vm.isRight,
          }},[_vm._v(_vm._s(_vm.data.orderNumber || "-"))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }