<template>
  <div class="card pt-3 pb-4 col-12">
    <h6
      class="card-title text-center"
      :class="{
        'text-lg-left': !isRight,
        'text-lg-right': isRight,
      }"
    >
      {{ $t("summary.invoice.header") }}
    </h6>

    <div class="card-body">
      <div class="form-inline">
        <div class="form-inline w-100">
          <label
            class="w-50"
            :class="{
              'justify-content-start': !isRight,
              'justify-content-end': isRight,
            }"
            >{{ $t("summary.invoice.number") }}:</label
          >
          <strong
            class="w-50"
            :class="{
              'justify-content-start': !isRight,
              'justify-content-end': isRight,
            }"
            >{{ data.invoiceNumber || "-" }}</strong
          >
        </div>

        <div class="form-inline w-100">
          <label
            class="w-50"
            :class="{
              'justify-content-start': !isRight,
              'justify-content-end': isRight,
            }"
            >{{ $t("summary.client.number") }}:</label
          >
          <strong
            :class="{
              'justify-content-start': !isRight,
              'justify-content-end': isRight,
            }"
            >{{ data.clientNumber || "-" }}</strong
          >
        </div>

        <div class="form-inline w-100">
          <label
            class="w-50"
            :class="{
              'justify-content-start': !isRight,
              'justify-content-end': isRight,
            }"
            >{{ $t("summary.order.number") }}:</label
          >
          <strong
            :class="{
              'justify-content-start': !isRight,
              'justify-content-end': isRight,
            }"
            >{{ data.orderNumber || "-" }}</strong
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VInvoiceData",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
