<template>
  <div class="card-body">
    <h5
      class="d-flex"
      v-bind:class="{
        'text-lg-left': !isRight,
        'text-lg-right': isRight,
      }"
    >
      <img
        class="img-icon d-flex"
        :class="{
          'mr-2': !isRight,
          'ml-2': isRight,
        }"
        src="@/assets/images/icon-return.png"
        alt="img-return"
      />

      <span class="d-flex">
        {{ $t("summary.return-address.header") }}
      </span>
    </h5>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.return-address.street") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.street || "-" }}</strong>
      </div>
    </div>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.return-address.city") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.city || "-" }}</strong>
      </div>
    </div>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.return-address.postal-code") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.postalCode || "-" }}</strong>
      </div>
    </div>

    <div class="row form-group mb-3 mb-sm-2">
      <div class="col-12 col-sm-5">
        <label>{{ $t("summary.return-address.country") }}</label>
      </div>

      <div class="col-12 col-sm-7 p-sm-0">
        <strong>{{ data.country || "-" }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VReturnAddress",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
