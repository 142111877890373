<template>
  <div class="card pt-3 pb-4 col-12">
    <h6
      class="card-title text-center"
      :class="{
        'text-lg-left': !isRight,
        'text-lg-right': isRight,
      }"
    >
      {{ $t("summary.complaint-type.header") }}
    </h6>

    <div class="card-body">
      <div class="form-group">
        <strong v-show="complaintType === 'WARRANTY'">
          {{ $t("label.complaint-type.warranty") }}
        </strong>

        <strong v-show="complaintType === 'GUARANTEE'">
          {{ $t("label.complaint-type.guarantee") }}
        </strong>

        <strong v-show="complaintType === 'RETURN'">
          {{ $t("label.complaint-type.return") }}
        </strong>

        <strong v-show="complaintType === 'TRANSPORTATION_DEMAGE'">
          {{ $t("label.complaint-type.transport-damage") }}
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VComplaintType",

  props: {
    isRight: {
      type: Boolean,
      default: false,
    },
    complaintType: {
      type: String,
      default: null,
    },
  },
};
</script>
