<template>
  <div class="row">
    <div class="col-12 card p-2 mb-3">
      <v-alert type="success" :message="$t('rma.send.success')" class="m-3" />

      <h3 class="mb-3 text-center summary-rma-number">
        <small>{{ $t("summary.rmaid") }}</small>
        {{ rmaId }}
      </h3>
    </div>
  </div>
</template>

<script>
import VAlert from "@/components/VAlert";

export default {
  name: "VSummaryHeader",

  components: {
    VAlert,
  },

  props: {
    rmaId: {
      type: String,
      default: null,
    },
  },
};
</script>
